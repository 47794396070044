<template>
  <div class="section-container content-container">
    <hg-link-list-text class="list-content">
      <template #gridContent>
        <p class="hg-short-intro">{{ $t('LinkList.shortIntro') }}</p>

        <div class="hg-destination-container">
          <!-- List for mobile -->
          <div class="hg-destination-list">
            <a
              v-for="(travel, index) in showDestinations[$i18n.locale]"
              :key="index"
            >
              <HgDestination
                :destination="travel.destination"
                :explanation="travel.explanation"
                class="hg-travel"
              />
            </a>
          </div>

          <!-- Grid for desktop -->
          <div class="hg-destination-grid">
            <div class="hg-destination-grid-left">
              <a
                v-for="(travel, index) in showDestinations[$i18n.locale]"
                v-if="index % 2 == 0"
                :key="index"
              >
                <HgDestination
                  :destination="travel.destination"
                  :explanation="travel.explanation"
                  class="hg-travel"
                />
              </a>
            </div>

            <div class="hg-destination-grid-right">
              <a
                v-for="(travel, index) in showDestinations[$i18n.locale]"
                v-if="index % 2 != 0"
                :key="index"
              >
                <HgDestination
                  :destination="travel.destination"
                  :explanation="travel.explanation"
                  class="hg-travel"
                />
              </a>
            </div>
          </div>
        </div>

        <!-- Last infos -->
        <div class="hg-text-container">
          <p
            v-html="$t('LinkList.explanationText.firstText')"
            v-if="firstText"
            class="text-first"
          />
          <p v-if="secondText">
            {{ $t('LinkList.explanationText.secondText') }}
          </p>
        </div>

        <p
          class="last-info-header"
          v-if="$t('LinkList.lastInfoHeader') != 'LinkList.lastInfoHeader'"
        >
          {{ $t('LinkList.lastInfoHeader') }}
        </p>
        <p v-html="$t('LinkList.lastInfo')" v-if="lastInfo" class="last-info" />
      </template>
    </hg-link-list-text>

    <!-- Dropdown content -->
    <hg-dropdown-new
      v-for="(item, index) in showItems[$i18n.locale]"
      :key="index"
      :trigger-text="item.headline"
      class="link-list-mobile"
    >
      <ul v-for="block in item.items" :key="`block_${index}`">
        <li
          v-for="(link, linkIndex) in block"
          :key="linkIndex"
          class="list-item"
        >
          <a :href="link.href">{{ link.name }}</a>
        </li>
      </ul>
    </hg-dropdown-new>

    <!-- Tab content -->
    <hg-tabs theme="light" class="link-list-desktop">
      <template
        v-for="(tab, index) in showItems[$i18n.locale]"
        :slot="tab.headline"
      >
        <div class="tab-content-wrap" :key="`tab_${index}`">
          <ul
            v-for="block in tab.items"
            :key="`block_${index}_${Math.floor(Math.random() * 999)}`"
          >
            <li
              v-for="(link, linkIndex) in block"
              :key="linkIndex"
              class="list-item list-item--desktop"
            >
              <a :href="link.href">{{ link.name }}</a>
            </li>
          </ul>
        </div>
      </template>
    </hg-tabs>
  </div>
</template>

<script>
export default {
  props: {
    lastInfo: {
      type: String,
      default: 'Default'
    },
    firstText: {
      type: String,
      default: 'Default'
    },
    secondText: {
      type: String,
      default: 'Default'
    }
  },
  data() {
    return {
      showDestinations: {
        de: [
          {
            destination: this.$t('LinkList.destinations.list.packageTour'),
            explanation: this.$t('LinkList.destinations.packageTour.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.shortTrip'),
            explanation: this.$t('LinkList.destinations.shortTrip.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.skiTrip'),
            explanation: this.$t('LinkList.destinations.skiTrip.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.holidayHomes'),
            explanation: this.$t('LinkList.destinations.holidayHomes.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.roundTrips'),
            explanation: this.$t('LinkList.destinations.roundTrips.sub')
          }
        ],
        at: [
          {
            destination: this.$t('LinkList.destinations.list.packageTour'),
            explanation: this.$t('LinkList.destinations.packageTour.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.shortTrip'),
            explanation: this.$t('LinkList.destinations.shortTrip.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.skiTrip'),
            explanation: this.$t('LinkList.destinations.skiTrip.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.holidayHomes'),
            explanation: this.$t('LinkList.destinations.holidayHomes.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.roundTrips'),
            explanation: this.$t('LinkList.destinations.roundTrips.sub')
          }
        ],
        ch: [
          {
            destination: this.$t('LinkList.destinations.list.packageTour'),
            explanation: this.$t('LinkList.destinations.packageTour.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.shortTrip'),
            explanation: this.$t('LinkList.destinations.shortTrip.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.skiTrip'),
            explanation: this.$t('LinkList.destinations.skiTrip.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.holidayHomes'),
            explanation: this.$t('LinkList.destinations.holidayHomes.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.roundTrips'),
            explanation: this.$t('LinkList.destinations.roundTrips.sub')
          }
        ],
        nl: [
          {
            destination: this.$t('LinkList.destinations.list.hotel'),
            explanation: this.$t('LinkList.destinations.hotel.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.fly'),
            explanation: this.$t('LinkList.destinations.fly.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.netherlands'),
            explanation: this.$t('LinkList.destinations.netherlands.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.zoekenEnBoeken'),
            explanation: this.$t('LinkList.destinations.zoekenEnBoeken.sub')
          }
        ],
        es: [
          {
            destination: this.$t('LinkList.destinations.list.packageTour'),
            explanation: this.$t('LinkList.destinations.packageTour.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.shortTrip'),
            explanation: this.$t('LinkList.destinations.shortTrip.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.roundTrips'),
            explanation: this.$t('LinkList.destinations.roundTrips.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.cruise'),
            explanation: this.$t('LinkList.destinations.cruise.sub')
          },
          {
            destination: this.$t('LinkList.destinations.list.lastMinute'),
            explanation: this.$t('LinkList.destinations.lastMinute.sub')
          }
        ]
      },
      showInspiration: [
        {
          destination: this.$t('LinkList.inspiration.head'),
          explanation: this.$t('LinkList.inspiration.sub')
        }
      ],
      showItems: {
        de: [
          {
            headline: this.$t('LinkList.listHead.travel'),
            items: [
              [
                {
                  href: this.$t('LinkList.destinations.allIn.link'),
                  name: this.$t('LinkList.destinations.allIn.head')
                },
                {
                  href: this.$t('LinkList.destinations.earlyBird.link'),
                  name: this.$t('LinkList.destinations.earlyBird.head')
                },
                {
                  href: this.$t('LinkList.destinations.lastMinute.link'),
                  name: this.$t('LinkList.destinations.lastMinute.head')
                },
                {
                  href: this.$t('LinkList.destinations.shortTrip.link'),
                  name: this.$t('LinkList.destinations.shortTrip.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.packageTour.link'),
                  name: this.$t('LinkList.destinations.packageTour.head')
                },
                {
                  href: this.$t('LinkList.destinations.weekendTrips.link'),
                  name: this.$t('LinkList.destinations.weekendTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.familyTrip.link'),
                  name: this.$t('LinkList.destinations.familyTrip.head')
                },
                {
                  href: this.$t('LinkList.destinations.cityTrip.link'),
                  name: this.$t('LinkList.destinations.cityTrip.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.singleTrip.link'),
                  name: this.$t('LinkList.destinations.singleTrip.head')
                },
                {
                  href: this.$t(
                    'LinkList.destinations.longDistanceTravel.link'
                  ),
                  name: this.$t('LinkList.destinations.longDistanceTravel.head')
                },
                {
                  href: this.$t('LinkList.destinations.groupTravel.link'),
                  name: this.$t('LinkList.destinations.groupTravel.head')
                },
                {
                  href: this.$t('LinkList.destinations.luxuryVacation.link'),
                  name: this.$t('LinkList.destinations.luxuryVacation.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.ownArrival.link'),
                  name: this.$t('LinkList.destinations.ownArrival.head')
                },
                {
                  href: this.$t('LinkList.destinations.holidayFlat.link'),
                  name: this.$t('LinkList.destinations.holidayFlat.head')
                },
                {
                  href: this.$t('LinkList.destinations.roundTrips.link'),
                  name: this.$t('LinkList.destinations.roundTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.wellness.link'),
                  name: this.$t('LinkList.destinations.wellness.head')
                }
              ]
            ]
          },
          {
            headline: this.$t('LinkList.listHead.destination'),
            items: [
              [
                {
                  href: this.$t('LinkList.specificDestinations.mallorca.link'),
                  name: this.$t('LinkList.specificDestinations.mallorca.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.greece.link'),
                  name: this.$t('LinkList.specificDestinations.greece.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.germany.link'),
                  name: this.$t('LinkList.specificDestinations.germany.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.turkey.link'),
                  name: this.$t('LinkList.specificDestinations.turkey.head')
                }
              ],
              [
                {
                  href: this.$t(
                    'LinkList.specificDestinations.fuerteventura.link'
                  ),
                  name: this.$t(
                    'LinkList.specificDestinations.fuerteventura.head'
                  )
                },
                {
                  href: this.$t('LinkList.specificDestinations.egypt.link'),
                  name: this.$t('LinkList.specificDestinations.egypt.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.austria.link'),
                  name: this.$t('LinkList.specificDestinations.austria.head')
                },
                {
                  href: this.$t(
                    'LinkList.specificDestinations.netherlands.link'
                  ),
                  name: this.$t(
                    'LinkList.specificDestinations.netherlands.head'
                  )
                }
              ]
            ]
          }
        ],
        at: [
          {
            headline: this.$t('LinkList.listHead.travel'),
            items: [
              [
                {
                  href: this.$t('LinkList.destinations.allIn.link'),
                  name: this.$t('LinkList.destinations.allIn.head')
                },
                {
                  href: this.$t('LinkList.destinations.earlyBird.link'),
                  name: this.$t('LinkList.destinations.earlyBird.head')
                },
                {
                  href: this.$t('LinkList.destinations.lastMinute.link'),
                  name: this.$t('LinkList.destinations.lastMinute.head')
                },
                {
                  href: this.$t('LinkList.destinations.shortTrip.link'),
                  name: this.$t('LinkList.destinations.shortTrip.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.packageTour.link'),
                  name: this.$t('LinkList.destinations.packageTour.head')
                },
                {
                  href: this.$t('LinkList.destinations.weekendTrips.link'),
                  name: this.$t('LinkList.destinations.weekendTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.familyTrip.link'),
                  name: this.$t('LinkList.destinations.familyTrip.head')
                },
                {
                  href: this.$t('LinkList.destinations.cityTrip.link'),
                  name: this.$t('LinkList.destinations.cityTrip.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.singleTrip.link'),
                  name: this.$t('LinkList.destinations.singleTrip.head')
                },
                {
                  href: this.$t(
                    'LinkList.destinations.longDistanceTravel.link'
                  ),
                  name: this.$t('LinkList.destinations.longDistanceTravel.head')
                },
                {
                  href: this.$t('LinkList.destinations.weddingTravel.link'),
                  name: this.$t('LinkList.destinations.weddingTravel.head')
                },
                {
                  href: this.$t('LinkList.destinations.luxuryVacation.link'),
                  name: this.$t('LinkList.destinations.luxuryVacation.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.hotelSearch.link'),
                  name: this.$t('LinkList.destinations.hotelSearch.head')
                },
                {
                  href: this.$t('LinkList.destinations.holidayFlat.link'),
                  name: this.$t('LinkList.destinations.holidayFlat.head')
                },
                {
                  href: this.$t('LinkList.destinations.roundTrips.link'),
                  name: this.$t('LinkList.destinations.roundTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.wellness.link'),
                  name: this.$t('LinkList.destinations.wellness.head')
                }
              ]
            ]
          },
          {
            headline: this.$t('LinkList.listHead.destination'),
            items: [
              [
                {
                  href: this.$t('LinkList.specificDestinations.mallorca.link'),
                  name: this.$t('LinkList.specificDestinations.mallorca.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.greece.link'),
                  name: this.$t('LinkList.specificDestinations.greece.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.austria.link'),
                  name: this.$t('LinkList.specificDestinations.austria.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.turkey.link'),
                  name: this.$t('LinkList.specificDestinations.turkey.head')
                }
              ],
              [
                {
                  href: this.$t(
                    'LinkList.specificDestinations.fuerteventura.link'
                  ),
                  name: this.$t(
                    'LinkList.specificDestinations.fuerteventura.head'
                  )
                },
                {
                  href: this.$t('LinkList.specificDestinations.egypt.link'),
                  name: this.$t('LinkList.specificDestinations.egypt.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.croatia.link'),
                  name: this.$t('LinkList.specificDestinations.croatia.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.italia.link'),
                  name: this.$t('LinkList.specificDestinations.italia.head')
                }
              ]
            ]
          }
        ],
        ch: [
          {
            headline: this.$t('LinkList.listHead.travel'),
            items: [
              [
                {
                  href: this.$t('LinkList.destinations.allIn.link'),
                  name: this.$t('LinkList.destinations.allIn.head')
                },
                {
                  href: this.$t('LinkList.destinations.earlyBird.link'),
                  name: this.$t('LinkList.destinations.earlyBird.head')
                },
                {
                  href: this.$t('LinkList.destinations.lastMinute.link'),
                  name: this.$t('LinkList.destinations.lastMinute.head')
                },
                {
                  href: this.$t('LinkList.destinations.shortTrip.link'),
                  name: this.$t('LinkList.destinations.shortTrip.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.packageTour.link'),
                  name: this.$t('LinkList.destinations.packageTour.head')
                },
                {
                  href: this.$t('LinkList.destinations.weekendTrips.link'),
                  name: this.$t('LinkList.destinations.weekendTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.familyTrip.link'),
                  name: this.$t('LinkList.destinations.familyTrip.head')
                },
                {
                  href: this.$t('LinkList.destinations.cityTrip.link'),
                  name: this.$t('LinkList.destinations.cityTrip.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.singleTrip.link'),
                  name: this.$t('LinkList.destinations.singleTrip.head')
                },
                {
                  href: this.$t(
                    'LinkList.destinations.longDistanceTravel.link'
                  ),
                  name: this.$t('LinkList.destinations.longDistanceTravel.head')
                },
                {
                  href: this.$t('LinkList.destinations.honeymoon.link'),
                  name: this.$t('LinkList.destinations.honeymoon.head')
                },
                {
                  href: this.$t('LinkList.destinations.luxuryVacation.link'),
                  name: this.$t('LinkList.destinations.luxuryVacation.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.hotelSearch.link'),
                  name: this.$t('LinkList.destinations.hotelSearch.head')
                },
                {
                  href: this.$t('LinkList.destinations.holidayFlat.link'),
                  name: this.$t('LinkList.destinations.holidayFlat.head')
                },
                {
                  href: this.$t('LinkList.destinations.roundTrips.link'),
                  name: this.$t('LinkList.destinations.roundTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.wellness.link'),
                  name: this.$t('LinkList.destinations.wellness.head')
                }
              ]
            ]
          },
          {
            headline: this.$t('LinkList.listHead.destination'),
            items: [
              [
                {
                  href: this.$t('LinkList.specificDestinations.mallorca.link'),
                  name: this.$t('LinkList.specificDestinations.mallorca.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.greece.link'),
                  name: this.$t('LinkList.specificDestinations.greece.head')
                },
                {
                  href: this.$t(
                    'LinkList.specificDestinations.switzerland.link'
                  ),
                  name: this.$t(
                    'LinkList.specificDestinations.switzerland.head'
                  )
                },
                {
                  href: this.$t('LinkList.specificDestinations.turkey.link'),
                  name: this.$t('LinkList.specificDestinations.turkey.head')
                }
              ],
              [
                {
                  href: this.$t(
                    'LinkList.specificDestinations.fuerteventura.link'
                  ),
                  name: this.$t(
                    'LinkList.specificDestinations.fuerteventura.head'
                  )
                },
                {
                  href: this.$t('LinkList.specificDestinations.egypt.link'),
                  name: this.$t('LinkList.specificDestinations.egypt.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.austria.link'),
                  name: this.$t('LinkList.specificDestinations.austria.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.croatia.link'),
                  name: this.$t('LinkList.specificDestinations.croatia.head')
                }
              ]
            ]
          }
        ],
        nl: [
          {
            headline: this.$t('LinkList.listHead.travel'),
            items: [
              [
                {
                  href: this.$t('LinkList.destinations.lastMinute.link'),
                  name: this.$t('LinkList.destinations.lastMinute.head')
                },
                {
                  href: this.$t('LinkList.destinations.weekendTrips.link'),
                  name: this.$t('LinkList.destinations.weekendTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.allIn.link'),
                  name: this.$t('LinkList.destinations.allIn.head')
                },
                {
                  href: this.$t('LinkList.destinations.accommodation.link'),
                  name: this.$t('LinkList.destinations.accommodation.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.packageTour.link'),
                  name: this.$t('LinkList.destinations.packageTour.head')
                },
                {
                  href: this.$t('LinkList.destinations.sunnyTrip.link'),
                  name: this.$t('LinkList.destinations.sunnyTrip.head')
                },
                {
                  href: this.$t('LinkList.destinations.cityTrip.link'),
                  name: this.$t('LinkList.destinations.cityTrip.head')
                },
                {
                  href: this.$t(
                    'LinkList.destinations.longDistanceTravel.link'
                  ),
                  name: this.$t('LinkList.destinations.longDistanceTravel.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.holidayguruTrips.link'),
                  name: this.$t('LinkList.destinations.holidayguruTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.roundTrips.link'),
                  name: this.$t('LinkList.destinations.roundTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.holidayWithKids.link'),
                  name: this.$t('LinkList.destinations.holidayWithKids.head')
                },
                {
                  href: this.$t('LinkList.destinations.summerHoliday.link'),
                  name: this.$t('LinkList.destinations.summerHoliday.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.wellness.link'),
                  name: this.$t('LinkList.destinations.wellness.head')
                },
                {
                  href: this.$t('LinkList.destinations.disneylandParis.sub'),
                  name: this.$t('LinkList.destinations.disneylandParis.head')
                },
                {
                  href: this.$t('LinkList.destinations.surpriseTrip.sub'),
                  name: this.$t('LinkList.destinations.surpriseTrip.head')
                },
                {
                  href: this.$t('LinkList.destinations.adultsOnly.sub'),
                  name: this.$t('LinkList.destinations.adultsOnly.head')
                }
              ]
            ]
          },
          {
            headline: this.$t('LinkList.listHead.destination'),
            items: [
              [
                {
                  href: this.$t('LinkList.specificDestinations.canaries.link'),
                  name: this.$t('LinkList.specificDestinations.canaries.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.curacao.link'),
                  name: this.$t('LinkList.specificDestinations.curacao.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.germany.link'),
                  name: this.$t('LinkList.specificDestinations.germany.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.greece.link'),
                  name: this.$t('LinkList.specificDestinations.greece.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.specificDestinations.ibiza.link'),
                  name: this.$t('LinkList.specificDestinations.ibiza.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.italia.link'),
                  name: this.$t('LinkList.specificDestinations.italia.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.spain.link'),
                  name: this.$t('LinkList.specificDestinations.spain.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.belgium.link'),
                  name: this.$t('LinkList.specificDestinations.belgium.head')
                }
              ],
              [
                {
                  href: this.$t(
                    'LinkList.specificDestinations.marrakesch.link'
                  ),
                  name: this.$t('LinkList.specificDestinations.marrakesch.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.barcelona.link'),
                  name: this.$t('LinkList.specificDestinations.barcelona.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.rom.link'),
                  name: this.$t('LinkList.specificDestinations.rom.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.valencia.link'),
                  name: this.$t('LinkList.specificDestinations.valencia.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.specificDestinations.lissabon.link'),
                  name: this.$t('LinkList.specificDestinations.lissabon.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.porto.link'),
                  name: this.$t('LinkList.specificDestinations.porto.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.milan.link'),
                  name: this.$t('LinkList.specificDestinations.milan.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.venice.link'),
                  name: this.$t('LinkList.specificDestinations.valencia.head')
                }
              ]
            ]
          }
        ],
        es: [
          {
            headline: this.$t('LinkList.listHead.travel'),
            items: [
              [
                {
                  href: this.$t('LinkList.destinations.lastMinute.link'),
                  name: this.$t('LinkList.destinations.lastMinute.head')
                },
                {
                  href: this.$t('LinkList.destinations.packageTour.link'),
                  name: this.$t('LinkList.destinations.packageTour.head')
                },
                {
                  href: this.$t('LinkList.destinations.weekendTrips.link'),
                  name: this.$t('LinkList.destinations.weekendTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.romanticWeekend.link'),
                  name: this.$t('LinkList.destinations.romanticWeekend.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.allIn.link'),
                  name: this.$t('LinkList.destinations.allIn.head')
                },
                {
                  href: this.$t('LinkList.destinations.roundTrips.link'),
                  name: this.$t('LinkList.destinations.roundTrips.head')
                },
                {
                  href: this.$t('LinkList.destinations.holidayWithKids.link'),
                  name: this.$t('LinkList.destinations.holidayWithKids.head')
                },
                {
                  href: this.$t('LinkList.destinations.wellness.link'),
                  name: this.$t('LinkList.destinations.wellness.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.destinations.groupTravel.link'),
                  name: this.$t('LinkList.destinations.groupTravel.head')
                },
                {
                  href: this.$t('LinkList.destinations.cruise.link'),
                  name: this.$t('LinkList.destinations.cruise.head')
                }
              ]
            ]
          },
          {
            headline: this.$t('LinkList.listHead.destination'),
            items: [
              [
                {
                  href: this.$t('LinkList.specificDestinations.spain.link'),
                  name: this.$t('LinkList.specificDestinations.spain.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.mallorca.link'),
                  name: this.$t('LinkList.specificDestinations.mallorca.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.canaries.link'),
                  name: this.$t('LinkList.specificDestinations.canaries.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.ibiza.link'),
                  name: this.$t('LinkList.specificDestinations.ibiza.head')
                }
              ],
              [
                {
                  href: this.$t('LinkList.specificDestinations.tenerife.link'),
                  name: this.$t('LinkList.specificDestinations.tenerife.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.andalucia.link'),
                  name: this.$t('LinkList.specificDestinations.andalucia.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.menorca.link'),
                  name: this.$t('LinkList.specificDestinations.menorca.head')
                },
                {
                  href: this.$t(
                    'LinkList.specificDestinations.greekIslands.link'
                  ),
                  name: this.$t(
                    'LinkList.specificDestinations.greekIslands.head'
                  )
                }
              ],
              [
                {
                  href: this.$t('LinkList.specificDestinations.portugal.link'),
                  name: this.$t('LinkList.specificDestinations.portugal.head')
                },
                {
                  href: this.$t('LinkList.specificDestinations.italia.link'),
                  name: this.$t('LinkList.specificDestinations.italia.head')
                }
              ]
            ]
          }
        ]
      }
    };
  }
};
</script>
<style lang="scss">
.hg-text-container {
  font-size: 16px;
  font-weight: 400;

  @media screen and (min-width: 1024px) {
    font-size: 18px;
  }
}

.text-first {
  a {
    color: $teal-600;
  }
}

.last-info {
  a {
    color: $teal-600;
  }
}
</style>

<style lang="scss" scoped>
.list-content {
  @media (min-width: 768px) {
    margin-bottom: rem(50);
  }
}

.section-container {
  padding: 0 rem(24);
}

.link-list {
  &-mobile {
    @media (min-width: 790px) {
      display: none;
    }
  }

  &-desktop {
    display: none;

    @media (min-width: 790px) {
      display: block;
      font-size: rem(18);

      ul {
        width: 25%;
      }
    }
  }
}

.tab-content-wrap {
  display: flex;
}

.list-item {
  padding: rem(10) rem(10) rem(10) 0;
  position: relative;
  font-size: rem(16);
  flex-direction: row;

  @media (min-width: 790px) {
    font-size: rem(18);
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    height: rem(1);
    width: 100%;
    background-color: #aef9fc;

    @media (min-width: 790px) {
      width: 90%;
    }
  }

  a {
    width: 100%;
    display: block;

    &:hover {
      color: #0197af;
    }
  }
}

.hg-short-intro {
  font-size: rem(16);
  margin-bottom: rem(16);

  @media screen and (min-width: 1024px) {
    margin-bottom: rem(18);
    font-size: rem(18);
  }
}

.last-info-header {
  font-size: rem(16);
  font-weight: bold;

  @media screen and (min-width: 1024px) {
    font-size: rem(18);
  }
}

.last-info {
  font-size: rem(16);
  font-weight: 400;

  @media screen and (min-width: 1024px) {
    font-size: rem(18);
  }
}

.hg-destination-container {
  margin-bottom: rem(16);
}

.hg-destination-list {
  gap: rem(16);

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.hg-destination-grid {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: rem(24);

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.hg-destination-grid-left,
.hg-destination-grid-right {
  display: flex;
  flex-direction: column;
  row-gap: rem(16);
}

.hg-destination-grid-left {
  grid-column: 1;
}

.hg-destination-grid-right {
  grid-column: 2;
}

.hg-text-container {
  @media screen and (min-width: 1024px) {
    border-top: 1px solid #aef9fc;
  }
}

.text-first {
  @media screen and (min-width: 1024px) {
    padding-top: rem(16);
  }
}
</style>
